.project{
    display: grid;
    grid-template-columns:600px 600px;
    grid-gap: 80px;
    width: 100%;
    justify-content: center;
    margin: auto;
}
.main_div{
    border: 1px solid #2c2c6c;
    padding: 20px;
    margin: auto;
    border-radius: 10px;
    background: #2c2c6c;
    transition: all 400ms ease;
}
.main_div:hover{
    background:#1f1f38 ;
}
.img_div>img{
    border-radius: 10px;
}
.button>a{
    margin-left: 70px;
}
.main_div>div:nth-child(2){
  text-align: left;
  margin-bottom: 20px;
}
.name_detail>h5{
    color: var(--color-light);
}
.name_detail>h2{
    color: var(--color-white);
}


/* ====== Media queries (Medium Devices) =======*/

@media screen and (max-width:1024px){
    .project{
     grid-template-columns:400px;  
     width: 600px;
    } 
    .button>a{
        margin-left: 40px;
    }
  }
  
  /* ====== Media queries (Small Devices) =======*/
  
  @media screen and (max-width:600px){
     .project{
         grid-template-columns:350px;  
         width: 350px;
        } 
        .button>a{
            margin-left: 35px;
        }
  }