/* header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_conatiner{
    text-align: center;
    height: 100%;
    position: relative;
} */

.name_detail{
  width: var(--container-width-lg);
  margin: auto;
  text-align: center;
  padding-top: 50px;
}
.name_detail>h4{
    font-size: 50px;
}
.name_detail>h1{
    font-size: 70px;
}
.img{
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 4rem;
}

/* =====CTA =====*/

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.main_header{
    display: flex;
    width: 90%;
    margin: auto;
}

/* ===== Header Socials =====*/

.header_socials{
    display: flex;
    align-items: center;
    margin-top: 4rem;
    gap: 0.8rem;
}

.header_socials::after{
    content: '';
    width: 1px;
    height: 1.5rem;
    background: var(--color-primary);
}

/* ==== ME =====*/

.me{
    background: linear-gradient(var(--color-primary),transparent);
    border-radius: 50%;
}
.me>img{
    border-radius: 50%;
}


/* ====== Media queries (Medium Devices) =======*/

@media screen and (max-width:1024px){
    .main_header{
        display:block;
    }
    .name_detail>h4{
        font-size: 30px;
    }
    .name_detail>h1{
        font-size: 50px;
    }
}

/* ====== Media queries (Small Devices) =======*/

@media screen and (max-width:600px){
    .main_header{
        display:block;
    }
    .name_detail>h4{
        font-size: 20px;
    }
    .name_detail>h1{
        font-size: 50px;
    }
}

@media screen and (max-width:370px){
    .main_header{
        display:block;
    }
    .name_detail>h4{
        font-size: 16px;
    }
    .name_detail>h1{
        font-size: 30px;
    }
}