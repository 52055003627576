.main_icon_div {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px;
  gap: 50px;
  width: 1200px;
  justify-content: center;
  margin: auto;
  padding: 20px;
}
.icon_div {
  border: 1px solid #2c2c6c;
  background: #2c2c6c;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  padding: 10%;
}
.icon_div:hover {
  background: #1f1f38;
}
.icon {
  color: #4db5ff;
  transition: all 400ms ease;
}

.icon:hover {
  color: white;
}

/* ====== Media queries (Medium Devices) =======*/

@media screen and (max-width: 1024px) {
  .main_icon_div {
    grid-template-columns: 150px 150px 150px;
    gap: 70px;
    width: 700px;
  }
}

/* ====== Media queries (Small Devices) =======*/

@media screen and (max-width: 600px) {
  .main_icon_div {
    grid-template-columns: 150px 150px;
    gap: 50px;
    width: 350px;
  }
  .icon_div {
    width: 150px;
  }
}
