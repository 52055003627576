footer{
    background: var(--color-primary);
    padding: 1rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 10rem;
}

footer a {
  color: var(--color-bg);   
}

.footer_logo{
 font-size: 2rem;
 font-weight: 500;
 margin-bottom: 0.5rem;
 display: inline-block;
}

.permalinks{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 1rem;  
}

.footer_socials{
 display: flex;
 justify-content: center;
 gap: 1rem;
 margin-bottom: 1rem;
}

.footer_socials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border:1px solid transparent;
}

.footer_copyright{
    margin-bottom: 5rem;
    color: var(--color-bg);
}

 /* ====== Media queries (Small Devices) =======*/
 
 @media screen and (max-width:600px){
  .permalinks{
      flex-direction: column;
      gap: 1.5rem;
  }
  .footer_socials{
      margin-bottom: 2.6rem;
  }
 }