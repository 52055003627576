.about {
    padding: 0px;
    width: 75%;
    margin: 20px auto;
    text-align: left;
}

.about_inner {
    width: 75%;
    padding: 0px;
    margin: 2px auto;
    text-align: left;
}

h5 {
    color: var(--color-light);
}

p {
    color: var(--color-light);
    font-size: 20px;
}

h2 {
    color: var(--color-primary);
}

.about_main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: auto;
}

.about_main>div:nth-child(2) {
    text-align: center;
}

.img_social {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-top: 4rem;
    align-items: center;
    padding-bottom: 50px;
}

.me {
    background: linear-gradient(var(--color-primary), transparent);
    border-radius: 50%;
    width: 380px;
}

.me>img {
    border-radius: 50%;
}


/* ====== Media queries (Medium Devices) =======*/

@media screen and (max-width:1280px) {
    .me {
        width: 295px;
    }
}

@media screen and (max-width:1024px) {
    .about_main {
        display: block;
    }

    .me {
        width: 400px;
    }
}

/* ====== Media queries (Small Devices) =======*/

@media screen and (max-width:600px) {
    .about_main {
        display: block;
    }

    p {
        font-size: 16px;
    }

    .me {
        width: 220px;
    }

    .about_inner {
        padding: 0px 10px;
        width: 90%;
    }

    .about {
        padding: 0px 10px;
        width: 90%;
    }
}